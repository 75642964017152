import axios from 'axios';
import router from '../router/index';
import tokenService from './tokenService';

const httpAuthClient = axios.create({
  baseUrl: `${window.location.protocol}//${window.location.host}`,
});

httpAuthClient.interceptors.request.use(async config => {
  const token = tokenService.get();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

httpAuthClient.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response !== undefined) {
      switch (error.response.status) {
        case 401:
          tokenService.remove();
          router.push('/admin/login');
          break;
        default:
          if (error.response.data) return Promise.reject(error.response.data);
          break;
      }
    }
    return Promise.reject(error);
  },
);

export default httpAuthClient;
