<template>
  <section class="section" id="relatorio-vendas">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column is-full">
          <h3 class="title is-3 has-text-primary">
            Relatório de Revendas
          </h3>
        </div>
        <b-field label="Período" class="column mb-0 is-one-quarter">
          <b-datepicker v-model="filtro.periodo" type="month" icon="calendar-today" trap-focus> </b-datepicker>
        </b-field>

        <div class="column is-one-fifth">
          <b-field label="Revendedor">
            <b-select v-model="filtro.revendaId" expanded>
              <option v-for="revenda in revendedores" :key="revenda.id" :value="revenda.id">
                {{ revenda.nome }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="column is-one-fifth mb-0" style="display: flex;align-items: flex-end;">
          <b-button type="is-secondary" expanded @click="getRevendas">
            Buscar
          </b-button>
        </div>
      </div>
      <hr />
      <div class="columns" v-if="agrupamentoVendas.length > 0 && !isLoading">
        <div class="column">
          <div class="card card-valores">
            <h5 class="title is-5">Total Período</h5>
            <p class="is-size-1 has-text-primary">
              {{ totalPeriodo | maskCurrency }}
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card card-valores">
            <h5 class="title is-5">Total Comissão</h5>
            <p class="is-size-1 has-text-success">
              {{ totalComissaoPeriodo | maskCurrency }}
            </p>
          </div>
        </div>
      </div>
      <section v-if="agrupamentoVendas.length === 0 && !isLoading" class="section has-background-light has-text-centered" style="border-radius: 10px">
        Nenhum resultado encontrado para a busca.
      </section>
      <div v-for="vendasAgrupadas in agrupamentoVendas" :key="vendasAgrupadas.data">
        <hr />
        <div class="header-sales-group">
          <div class="date-baloon">
            <h5 class="title is-5">{{ vendasAgrupadas.data }}</h5>
          </div>
          <div class="wrapper-valores">
            <div class="valor-baloon">
              <h5>
                Total de Comissão:
                <span class="has-text-success">
                  {{ vendasAgrupadas.totalComissaoPeriodo | maskCurrency }}
                </span>
              </h5>
            </div>
            <div class="valor-baloon">
              <h5>
                Total:
                <span class="has-text-primary">
                  {{ vendasAgrupadas.totalPeriodo | maskCurrency }}
                </span>
              </h5>
            </div>
          </div>
        </div>

        <b-table :data="vendasAgrupadas.vendas" :loading="isLoading" hoverable mobile-cards detailed detail-key="id" show-detail-icon>
          <b-table-column field="id" label="ID" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="nome" label="Nome" centered v-slot="props"> {{ props.row.nome }} {{ props.row.sobrenome }} </b-table-column>

          <b-table-column field="valorTotal" label="Valor Total Ingressos" centered v-slot="props">
            {{ props.row.valorTotalIngressos | maskCurrency }}
          </b-table-column>

          <b-table-column field="comissao" label="Valor Comissão" centered v-slot="props">
            {{ calcularComissao(props.row, true) }}
          </b-table-column>

          <b-table-column label="Ações" centered v-slot="props">
            <b-button @click="abrirDetalhes(props.row)">
              <b-icon icon="magnify-plus-outline" />
            </b-button>
          </b-table-column>

          <template slot="detail" slot-scope="props">
            <b-table :data="props.row.detalhes" :loading="isLoading" hoverable mobile-cards>
              <b-table-column field="tipo" label="Tipo" centered v-slot="props">
                <span class="is-capitalized">
                  {{ props.row.tipo }}
                </span>
              </b-table-column>

              <b-table-column field="quantidade" label="Quantidade" numeric centered v-slot="props">
                {{ props.row.quantidade }}
              </b-table-column>

              <b-table-column field="valorUnitario" label="Valor Unitário" numeric centered v-slot="props">
                {{ props.row.valorUnitario | maskCurrency }}
              </b-table-column>

              <b-table-column field="valorTotal" label="Valor Total" numeric centered v-slot="props">
                {{ props.row.valorTotal | maskCurrency }}
              </b-table-column>

              <b-table-column field="data" label="Data" centered v-slot="props">
                {{ props.row.data | maskDate }}
              </b-table-column>
            </b-table>
          </template>
        </b-table>
      </div>

      <ModalDetalheVenda :ativo="modalDetalheVendaAtivo" :venda="vendaSelecionada" @fechar="fecharDetalhes" />
    </div>
  </section>
</template>

<script>
import ModalDetalheVenda from '@/components/Relatorios/ModalDetalheVenda.vue';
import vendaService from '@/services/vendaService';
import revendaService from '@/services/revendaService';
import { maskDate } from '@/helpers/helpers';

export default {
  name: 'RelatorioDeRevendas',
  components: {
    ModalDetalheVenda,
  },
  data() {
    return {
      filtro: {
        periodo: new Date(),
        revendaId: null,
      },
      vendaObject: {},
      isLoading: false,
      modalCancelarVendaAtivo: false,
      modalConfirmarVendaAtivo: false,
      modalDetalheVendaAtivo: false,
      vendaSelecionada: {},
      vendas: [],
      revendedores: [],
    };
  },
  computed: {
    agrupamentoVendas() {
      if (this.vendas.length > 0) {
        let arrayDatas = [];

        const { periodo } = this.filtro;
        arrayDatas = this.getDateRange(periodo);

        const agrupamento = [];

        arrayDatas.forEach(data => {
          const vendasPorData = this.vendas.filter(i => maskDate(i.dataCadastro) === data);

          const totalPeriodo = this.calcularValorTotal(vendasPorData);
          const totalComissaoPeriodo = this.calcularValorComissoes(vendasPorData);

          if (vendasPorData.length > 0) {
            agrupamento.push({
              data,
              vendas: vendasPorData,
              totalPeriodo,
              totalComissaoPeriodo,
            });
          }
        });

        return agrupamento;
      }

      return [];
    },
    totalComissaoPeriodo() {
      return this.calcularValorComissoes(this.vendas);
    },
    totalPeriodo() {
      return this.calcularValorTotal(this.vendas);
    },
  },
  created() {
    this.getRevendas();
    this.getRevendedores();
  },
  methods: {
    getRevendas() {
      const { periodo, revendaId } = this.filtro;

      const ano = periodo.getFullYear();
      const mes = periodo.getMonth();

      this.isLoading = true;
      vendaService
        .getRelatorioRevendas({ ano, mes, revendaId })
        .then(({ data }) => {
          this.vendas = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getRevendedores() {
      revendaService.getAll().then(({ data }) => {
        this.revendedores = data;
      });
    },
    getDateRange(periodo) {
      const dateArray = new Array();

      const firstDayOfMonth = new Date(periodo.getFullYear(), periodo.getMonth(), 1);
      const lastDayOfMonth = new Date(periodo.getFullYear(), periodo.getMonth() + 1, 0);

      let currentDate = firstDayOfMonth;
      while (currentDate <= lastDayOfMonth) {
        dateArray.push(maskDate(currentDate));
        currentDate = currentDate.addDays(1);
      }
      return dateArray;
    },
    abrirDetalhes(venda) {
      this.vendaSelecionada = venda;
      this.modalDetalheVendaAtivo = true;
    },
    fecharDetalhes() {
      this.vendaSelecionada = {};
      this.modalDetalheVendaAtivo = false;
    },
    calcularValorTotal(vendas) {
      return vendas.map(venda => venda.valorTotalIngressos).reduce((acc, valor) => parseFloat(acc) + parseFloat(valor), 0);
    },
    calcularValorComissoes(vendas) {
      return vendas.map(venda => this.calcularComissao(venda)).reduce((acc, valor) => parseFloat(acc) + parseFloat(valor), 0);
    },
    calcularComissao(venda, maskCurrency = false) {
      const { valorTotalIngressos, revenda } = venda;
      const valorComissao = (Number(valorTotalIngressos) * Number(revenda.percentualComissao)) / 100;

      if (maskCurrency) {
        return this.$options.filters.maskCurrency(valorComissao);
      } else {
        return valorComissao;
      }
    },
  },
};
</script>

<style lang="scss">
#relatorio-vendas {
  .header-sales-group {
    .date-baloon {
      display: inline-block;
      background-color: #ff6d01;
      width: 200px;
      padding: 18px;
      border-radius: 15pc;
      text-align: center;
      margin-bottom: 20px;
      margin-right: 10px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
      h5 {
        color: #fff;
      }
    }

    .valor-baloon {
      float: right;
      width: fit-content;
      padding: 14px;
      border-radius: 10px;
      text-align: center;
      margin-bottom: 20px;
      margin-right: 10px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

      h5 {
        display: flex;
        align-items: center;
        font-weight: 500;
        span {
          font-size: 20px;
          margin-left: 5px;
        }
      }
    }
  }

  .b-table {
    .table tr.detail {
      background-color: whitesmoke;
      .detail-container {
        padding: 0;
        .table {
          background-color: whitesmoke;
        }
      }
    }

    td {
      vertical-align: middle;
    }
  }

  .card-valores {
    border-radius: 10px;
    padding: 2rem;
    h5 {
      margin-bottom: 5px;
    }
  }
}
</style>
