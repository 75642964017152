const CODIGO_REVENDA_KEY = 'olimpiaparques.codigoRevenda';

import httpClient from './httpAuthClient';
const url = `revenda`;

export default {
  set(codigoRevenda) {
    sessionStorage.setItem(CODIGO_REVENDA_KEY, codigoRevenda);
  },
  get() {
    return sessionStorage.getItem(CODIGO_REVENDA_KEY);
  },
  remove() {
    sessionStorage.removeItem(CODIGO_REVENDA_KEY);
  },
  getAll() {
    return httpClient.get(`/${url}`);
  },
};
