import httpClient from './httpClient';
import httpAuthClient from './httpAuthClient';

const url = `venda`;

export default {
  getById(id) {
    return httpClient.get(`${url}/${id}`);
  },
  getByIdPrivate(id) {
    return httpAuthClient.get(`/${url}/GetById?id=${id}`);
  },
  getParcelas(vendaId) {
    return httpClient.get(`${url}/${vendaId}/parcelas`);
  },
  post(data) {
    return httpClient.post(url, data);
  },
  confirmarTelefone(id, data) {
    return httpClient.post(`${url}/${id}/ConfirmarTelefone`, data);
  },
  reenviarCodigoConfirmacao(id) {
    return httpClient.post(`${url}/${id}/ReenviarCodigoConfirmacao`);
  },
  pagamento(vendaId, data) {
    return httpClient.post(`${url}/${vendaId}/pagamento`, data);
  },
  verificaStatusPagamento(vendaId) {
    return httpClient.get(`${url}/${vendaId}/status-pagamento`);
  },
  getByRangeData({ dataI, dataF, pago }) {
    return httpAuthClient.get(`/${url}/GetByRangeData?dataI=${dataI}&dataF=${dataF}&pago=${Boolean(pago)}`);
  },
  getByName(nome) {
    return httpAuthClient.get(`/${url}/GetByName?nome=${nome}`);
  },
  getByCpf(cpf) {
    return httpAuthClient.get(`/${url}/GetByCpf?cpf=${cpf}`);
  },
  getRelatorioRevendas({ ano, mes, revendaId }) {
    let path = `/${url}/GetRelatorioRevenda?ano=${ano}&mes=${mes}`;

    if (revendaId) {
      path = path.concat(`&revendaId=${revendaId}`);
    }

    return httpAuthClient.get(path);
  },
  getQuantidadeVendidoByRangeData({ dataI, dataF, parqueId }) {
    return httpAuthClient.get(`/${url}/GetQuantidadeVendidoByRangeData?dataI=${dataI}&dataF=${dataF}&parqueId=${parqueId}`);
  },
  getRelatorioEntregaIngressos({ dataI, dataF, retirado, vendaId }) {
    return httpAuthClient.get(`/${url}/GetRelatorioEntregaIngressos?dataI=${dataI}&dataF=${dataF}&retirado=${retirado}&vendaId=${vendaId}`);
  },
  confirmarEntrega(id, data) {
    return httpAuthClient.post(`/${url}/${id}/ConfirmarEntrega`, data);
  },
  editar(data) {
    return httpAuthClient.post(`/${url}/editar`, data);
  },
  cancelar({ id }, data) {
    return httpAuthClient.post(`/${url}/${id}/cancelar`, data);
  },
  confirmar({ id }, data) {
    return httpAuthClient.post(`/${url}/${id}/confirma-pagamento-manual`, data);
  },
};
