<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <div class="modal-card modal-detalhe-venda">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Pagamentos #{{ venda.id }}</b>
        </p>
      </header>
      <section class="modal-card-body">
        <b-table :data="venda.pagamentos" hoverable mobile-cards>
          <b-table-column field="nsu" label="nsu" centered v-slot="props">
            {{ props.row.nsu }}
          </b-table-column>

          <b-table-column field="formaPagamentoId" label="Forma Pagamento" centered v-slot="props">
            {{ getFormaPagamento(props.row.formaPagamentoId) }}
          </b-table-column>

         <b-table-column field="nome" label="Nome" centered v-slot="props">
            {{ props.row.nome || '-' }}
          </b-table-column>

          <b-table-column field="numero" label="Número" centered v-slot="props">
             {{ props.row.numero || '-' }}
          </b-table-column>

          <b-table-column field="codigoTransacao" label="Cod. Transação" numeric centered v-slot="props">
            {{ props.row.codigoTransacao || '-' }}
          </b-table-column>

          <b-table-column field="mensagemRetorno" label="Mensagem Retorno" numeric centered v-slot="props">
            {{ props.row.mensagemRetorno || '-' }}
          </b-table-column>

          <b-table-column field="qtdParcela" label="Qtd. Parcelas" numeric centered v-slot="props">
            {{ props.row.qtdParcela }}
          </b-table-column>

          <b-table-column field="valorParcela" label="Valor Parcela" numeric centered v-slot="props">
            {{ props.row.valorParcela | maskCurrency }}
          </b-table-column>

          <b-table-column field="aprovado" label="Aprovado" centered v-slot="props">
            <b-icon :icon="props.row.aprovado ? 'check-bold' : 'close-thick'" :type="props.row.aprovado ? 'is-success' : 'is-danger'"></b-icon>
          </b-table-column>
        </b-table>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" name="cancelar" @click="fechar">
          Fechar
        </button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalDetalheVenda',
  props: {
    venda: {
      type: Object,
      required: true,
    },
    ativo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalAtivo: false,
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.fechar();
      }
    },
  },
  methods: {
    fechar() {
      this.$emit('fechar');
    },
    getFormaPagamento(formaPagamentoId) {
      if (formaPagamentoId === 1) {
        return 'Crédito';
      }

      if (formaPagamentoId === 2) {
        return 'Débito';
      }

      if (formaPagamentoId === 3) {
        return 'MercadoPago';
      }

      if (formaPagamentoId === 4) {
        return 'Transferência';
      }
    },
  },
};
</script>

<style lang="scss">
.modal-detalhe-venda {
  width: 1000px;
  height: 564px;
  max-width: 90%;
  margin: auto !important;

  .modal-card-head {
    border-bottom: none;
    background-color: #fff;
    .modal-card-title {
      color: #ff6d01;
    }
  }

  header {
    box-shadow: none;
  }

  .modal-card-foot {
    border-top: none;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
  }

  table {
    td,
    th {
      vertical-align: middle;
    }
  }
}
</style>
